import createRepository from './repository/create-repository'

export default ({ $axios }) => {
  const repository = createRepository({ $axios, resource: 'themes' })

  /**
   * Returns all themes of a given workspace.
   *
   * @param {String} workspaceId
   * @returns {Promise<Array<{Object}>>} Returns an array of themes.
   * @memberof ThemesService
   */
  async function find (workspaceId) {
    return (await repository.find(`workspaces/${workspaceId}/themes`)).data
  }

  /**
   * Returns a theme with the given themeId.
   *
   * @param {String} workspaceId
   * @param {String} themeId
   * @returns {Promise<{Object}>} Returns a theme.
   * @memberof ThemesService
   */
  async function findOne (themeId) {
    return await repository.findOne('themes', themeId)
  }

  /**
   * Creates a new theme.
   *
   * @param {String} workspaceId
   * @returns {Promise<Object>} Returns the new theme created.
   * @memberof ThemesService
   */
  async function create (workspaceId, name, fontName, fieldTextColor, answerTextColor, buttonBackgroundColor, buttonTextColor, pageBackgroundColor, fontSizeScale, buttonBorderRadius) {
    return await repository.create(`workspaces/${workspaceId}/themes`, { name, fontName, fieldTextColor, answerTextColor, buttonBackgroundColor, buttonTextColor, pageBackgroundColor, fontSizeScale, buttonBorderRadius })
  }

  /**
   * Duplicates a theme.
   *
   * @param {String} workspaceId
   * @param {String} cloneThemeId
   * @returns {Promise<Object>} Returns the new theme created.
   * @memberof ThemesService
   */
  async function duplicate (workspaceId, cloneThemeId) {
    return await repository.create(`workspaces/${workspaceId}/themes`, { cloneThemeId })
  }

  /**
   * Updates a theme.
   *
   * @param {String} themeId
   * @param {Object} theme
   * @returns {Promise<Object>} Returns the updated theme.
   * @memberof ThemesService
   */
  async function update (themeId, theme) {
    return await repository.update('themes', themeId, theme)
  }

  /**
   * Sets the deletedAt attribute of the given theme to the current date and time.
   *
   * @param {String} workspaceId
   * @param {String} themeId
   * @returns {Promise<string>} Returns the id of the deleted theme.
   * @memberof ThemesService
   */
  async function del (themeId) {
    return await repository.delete('themes', themeId)
  }

  return {
    create,
    delete: del,
    duplicate,
    find,
    findOne,
    update
  }
}
