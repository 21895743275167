export const state = () => ({
  unreadCounts: {
    all: 0,
    onboarding: 0,
    general: 0
  },
  activePreviewId: null,
  previewPriorities: {},
  initialPreviewShown: false
})

export const mutations = {
  incrementUnreadCount (state, category = 'all') {
    if (state.unreadCounts[category] !== undefined) {
      state.unreadCounts = {
        ...state.unreadCounts,
        [category]: state.unreadCounts[category] + 1
      }
    }
  },

  decrementUnreadCount (state, category = 'all') {
    if (state.unreadCounts[category] !== undefined && state.unreadCounts[category] > 0) {
      state.unreadCounts = {
        ...state.unreadCounts,
        [category]: state.unreadCounts[category] - 1
      }
    }
  },

  setUnreadCount (state, { category = 'all', count }) {
    state.unreadCounts = {
      ...state.unreadCounts,
      [category]: count
    }
  },

  resetUnreadCount (state, category = 'all') {
    state.unreadCounts = {
      ...state.unreadCounts,
      [category]: 0
    }
  },

  setActivePreviewId (state, id) {
    state.activePreviewId = id
  },

  registerPreviewPriority (state, { id, priority, unreadCount }) {
    state.previewPriorities[id] = { priority, unreadCount }
  },

  unregisterPreview (state, id) {
    delete state.previewPriorities[id]
    if (state.activePreviewId === id) {
      state.activePreviewId = null
    }
  },

  setInitialPreviewShown (state, value) {
    state.initialPreviewShown = value
  }
}

export const actions = {
  async fetchUnreadCount ({ commit }, { category = 'all', params = {} } = {}) {
    try {
      const count = await this.$notificationsService.getUnreadCount(params)
      commit('setUnreadCount', { category, count })
    } catch (error) {
      commit('setUnreadCount', { category, count: 0 })
    }
  },

  showInitialPreview ({ commit, state }) {
    if (state.initialPreviewShown) {
      return
    }

    // First find all dropdowns with unread notifications
    const activeDropdowns = Object.entries(state.previewPriorities)
      .filter(([, { unreadCount }]) => unreadCount > 0)

    if (activeDropdowns.length === 0) {
      return
    }

    // Then find the one with highest priority (lowest number)
    const highestPriorityId = activeDropdowns
      .reduce((highest, [id, { priority }]) => {
        if (!highest || priority < highest.priority) {
          return { id, priority }
        }
        return highest
      }, null)

    if (highestPriorityId) {
      commit('setActivePreviewId', highestPriorityId.id)
      commit('setInitialPreviewShown', true)
    }
  },

  showPreview ({ commit, state }, id) {
    // If we're showing initial preview, don't allow other previews
    if (!state.initialPreviewShown) {
      commit('setActivePreviewId', id)
    }
  },

  hidePreview ({ commit, state }, id) {
    // Only hide preview if it's not the initial one
    if (state.initialPreviewShown && state.activePreviewId === id) {
      commit('setActivePreviewId', null)
      commit('setInitialPreviewShown', false)
    }
  }
}
