import Pusher from 'pusher-js'

export default {
  data () {
    return {
      notificationPusher: null,
      reconnectAttempts: 0,
      maxReconnectAttempts: 5
    }
  },

  computed: {
    notificationChannel () {
      if (!this.$auth.user) {
        return null
      }
      return `User.${this.$auth.user.id}`
    }
  },

  async mounted () {
    await this.updateUnreadCount()
    this.initializePusher()
    window.addEventListener('online', this.handleOnline)
  },

  beforeDestroy () {
    if (this.notificationPusher) {
      this.notificationPusher.disconnect()
    }
    window.removeEventListener('online', this.handleOnline)
  },

  methods: {
    handleOnline () {
      this.reconnectPusher()
    },

    async reconnectPusher () {
      if (this.reconnectAttempts >= this.maxReconnectAttempts) {
        return
      }

      this.reconnectAttempts++

      if (this.notificationPusher) {
        this.notificationPusher.disconnect()
        this.notificationPusher = null
      }

      await this.initializePusher()
    },

    async updateUnreadCount () {
      try {
        const totalCount = await this.$notificationsService.getUnreadCount({ read: false })
        this.$store.commit('notifications/setUnreadCount', { category: 'all', count: totalCount })

        if (!this.categories?.length) {
          const generalCount = await this.$notificationsService.getUnreadCount({
            read: false,
            excludeCategories: ['onboarding']
          })
          this.$store.commit('notifications/setUnreadCount', {
            category: 'general',
            count: generalCount
          })
        } else {
          const category = this.categories[0]
          const categoryCount = await this.$notificationsService.getUnreadCount({
            read: false,
            categories: [category]
          })
          this.$store.commit('notifications/setUnreadCount', {
            category,
            count: categoryCount
          })
        }
      } catch (error) {
        console.error('NotificationsMixin - Error updating unread count:', error)
      }
    },

    initializePusher () {
      try {
        if (!this.$auth.user) {
          return
        }

        if (this.notificationPusher) {
          return
        }

        if (!process.env.PUSHER_APP_KEY) {
          console.error('Pusher app key is not defined')
          return
        }

        this.notificationPusher = new Pusher(process.env.PUSHER_APP_KEY, {
          cluster: process.env.PUSHER_APP_CLUSTER,
          encrypted: true,
          wsHost: process.env.PUSHER_HOST,
          wsPort: process.env.PUSHER_PORT,
          forceTLS: false,
          disableStats: true,
          enabledTransports: ['ws', 'wss'],
          debug: true
        })

        this.notificationPusher.connection.bind('connecting', () => {
        })

        this.notificationPusher.connection.bind('connected', () => {
          this.reconnectAttempts = 0
        })

        this.notificationPusher.connection.bind('failed', () => {
          setTimeout(() => this.reconnectPusher(), 5000)
        })

        this.notificationPusher.connection.bind('disconnected', () => {
          setTimeout(() => this.reconnectPusher(), 5000)
        })

        this.notificationPusher.connection.bind('error', (error) => {
          console.error('Pusher error:', error)
        })

        const channel = this.notificationPusher.subscribe(this.notificationChannel)

        channel.bind('pusher:subscription_succeeded', () => {
        })

        channel.bind('pusher:subscription_error', (error) => {
          console.error('Error subscribing to channel:', error)
        })

        channel.bind('Illuminate\\Notifications\\Events\\BroadcastNotificationCreated', async (notification) => {
          try {
            this.$store.commit('notifications/incrementUnreadCount', 'all')

            if (this.categories?.length && notification.data?.category) {
              if (this.categories.includes(notification.data.category)) {
                this.$store.commit('notifications/incrementUnreadCount', notification.data.category)
              }
            }
            await this.updateUnreadCount()
          } catch (error) {
            console.error('Error handling Laravel broadcast notification:', error)
          }
        })
      } catch (error) {}
    }
  }
}
