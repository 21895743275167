// https://gist.github.com/lopspower/03fb1cc0ac9f32ef38f4
const opacity = {
  10: '1A',
  20: '33',
  25: '40',
  30: '4D',
  40: '66',
  50: '7F',
  60: '99',
  70: 'B2',
  80: 'CC',
  90: 'E5'
}

export default {
  properties: {
    fieldTextColor: {
      key: 'fieldTextColor',
      name: 'lib.utils.theme.fields',
      var: '--theme-field-color',
      value: '',
      setValue (value) {
        document.body.style.setProperty(this.var, value)
        this.value = value
      }
    },
    answerTextColor: {
      key: 'answerTextColor',
      name: 'lib.utils.theme.answers',
      var: '--theme-answer-color',
      value: '',
      setValue (value) {
        document.body.style.setProperty(this.var, value)
        this.value = value
      }
    },
    answerBackgroundColor: {
      key: 'answerBackgroundColor',
      name: 'lib.utils.theme.answers',
      var: '--theme-answer-bg',
      value: '',
      setValue (value) {
        document.documentElement.style.setProperty(this.var, value + opacity[10])
        this.value = value + opacity[10]
      }
    },
    answerBackgroundColorHover: {
      key: 'answerBackgroundColorHover',
      name: 'lib.utils.theme.answers',
      var: '--theme-answer-bg-hover',
      value: '',
      setValue (value) {
        document.documentElement.style.setProperty(this.var, value + opacity[25])
        this.value = value + opacity[25]
      }
    },
    buttonBackgroundColor: {
      key: 'buttonBackgroundColor',
      name: 'lib.utils.theme.buttons',
      var: '--theme-button-bg',
      value: '',
      setValue (value) {
        document.body.style.setProperty(this.var, value)
        this.value = value
      }
    },
    buttonTextColor: {
      key: 'buttonTextColor',
      name: 'lib.utils.theme.buttonText',
      var: '--theme-button-text',
      value: '',
      setValue (value) {
        document.body.style.setProperty(this.var, value)
        this.value = value
      }
    },
    pageBackgroundColor: {
      key: 'pageBackgroundColor',
      name: 'lib.utils.theme.Background',
      var: '--theme-page-bg',
      value: '',
      setValue (value) {
        document.body.style.setProperty(this.var, value)
        this.value = value
      }
    },
    fontSizeScale: {
      key: 'fontSizeScale',
      label: 'Font size scale',
      name: 'font-size-scale',
      var: '--font-size-scale',
      value: '',
      help: 'Font size in pixels',
      setValue (value) {
        document.body.style.setProperty(this.var, value)
        this.value = value
      }
    },
    buttonBorderRadius: {
      key: 'buttonBorderRadius',
      label: 'button Border Radius',
      name: 'button-border-radius',
      var: '--button-border-radius',
      value: '',
      help: 'buttonBorderRadius',
      setValue (value) {
        document.body.style.setProperty(this.var, value)
        this.value = value
      }
    },
    fontName: {
      key: 'fontName',
      name: 'lib.utils.theme.Font',
      var: '--theme-font-name',
      value: '',
      setValue (value) {
        document.body.style.setProperty(this.var, value)
        this.value = value
      }
    }

  },

  /**
   * Sets the value of each css variable.
   *
   * @param {*} { fieldTextColor, answerTextColor, buttonBackgroundColor, buttonTextColor, pageBackgroundColor, fontName }
   */
  init ({ fieldTextColor, answerTextColor, buttonBackgroundColor, buttonTextColor, pageBackgroundColor, fontSizeScale, buttonBorderRadius, fontName }) {
    this.properties.fieldTextColor.setValue(fieldTextColor)
    this.properties.answerTextColor.setValue(answerTextColor)
    this.properties.answerBackgroundColor.setValue(answerTextColor)
    this.properties.answerBackgroundColorHover.setValue(answerTextColor)
    this.properties.buttonBackgroundColor.setValue(buttonBackgroundColor)
    this.properties.buttonTextColor.setValue(buttonTextColor)
    this.properties.pageBackgroundColor.setValue(pageBackgroundColor)
    this.properties.fontSizeScale.setValue(fontSizeScale)
    this.properties.buttonBorderRadius.setValue(buttonBorderRadius)
    this.properties.fontName.setValue(fontName)
    this.loadGoogleFont(fontName)
  },

  /**
   * Loads the font from Google Fonts.
   * @param {String} fontName
   * @returns
   */
  loadGoogleFont (fontName) {
    const href = `https://fonts.googleapis.com/css?family=${fontName}:400,700&display=swap`

    // Detect if the link was already added to the head.
    const isLoaded = document.querySelectorAll(`[href="${href}"]`).length > 0

    if (!isLoaded) {
      const link = document.createElement('link')
      link.rel = 'stylesheet'
      link.media = 'all'
      link.href = href
      document.head.appendChild(link)
    }
  },

  /**
   * Returns the colors among properties.
   *
   * @param {*} { fieldTextColor, answerTextColor, buttonBackgroundColor, buttonTextColor, pageBackgroundColor }
   * @returns Array of property
   */
  getColors ({ fieldTextColor, answerTextColor, buttonBackgroundColor, buttonTextColor, pageBackgroundColor }) {
    this.properties.fieldTextColor.value = fieldTextColor
    this.properties.answerTextColor.value = answerTextColor
    this.properties.answerBackgroundColor.value = answerTextColor + opacity[10]
    this.properties.buttonBackgroundColor.value = buttonBackgroundColor
    this.properties.buttonTextColor.value = buttonTextColor
    this.properties.pageBackgroundColor.value = pageBackgroundColor

    return [
      this.properties.fieldTextColor,
      this.properties.answerTextColor,
      this.properties.buttonBackgroundColor,
      this.properties.buttonTextColor,
      this.properties.pageBackgroundColor
    ]
  }
}
