
import NotificationsDropdown from '~/components/notifications/NotificationsDropdown.vue'

export default {
  name: 'TheNotificationsDropdown',

  components: {
    NotificationsDropdown
  },

  data () {
    return {
      mountedDropdowns: 0
    }
  },

  methods: {
    handleDropdownMounted () {
      this.mountedDropdowns++
      if (this.mountedDropdowns === 2) {
        this.$store.dispatch('notifications/showInitialPreview')
      }
    }
  }
}
