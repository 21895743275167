import createRepository from './repository/create-repository'

export default ({ $axios }) => {
  const repository = createRepository({ $axios, resource: 'recipes' })

  /**
   * Returns all recipes of a given workspace.
   *
   * @param {String} workspaceId
   * @returns {Promise<Array<{Object}>>} Returns an array of recipes.
   * @memberof RecipesService
   */
  async function find (workspaceId) {
    return (await repository.find(`workspaces/${workspaceId}/recipes`)).data
  }

  /**
   * Returns a recipe with the given recipeId.
   *
   * @param {String} workspaceId
   * @param {String} recipeId
   * @returns {Promise<{Object}>} Returns a recipe.
   * @memberof RecipesService
   */
  async function findOne (recipeId) {
    return await repository.findOne('recipes', recipeId)
  }

  /**
   * Creates a new recipe.
   *
   * @param {String} workspaceId
   * @returns {Promise<Object>} Returns the new recipe created.
   * @memberof RecipesService
   */
  async function create (workspaceId, name, fontName, fieldTextColor, answerTextColor, buttonBackgroundColor, buttonTextColor, pageBackgroundColor, fontSizeScale, buttonBorderRadius) {
    return await repository.create(`workspaces/${workspaceId}/recipes`, { name, fontName, fieldTextColor, answerTextColor, buttonBackgroundColor, buttonTextColor, pageBackgroundColor, fontSizeScale, buttonBorderRadius })
  }

  /**
   * Duplicates a recipe.
   *
   * @param {String} workspaceId
   * @param {String} cloneRecipeId
   * @returns {Promise<Object>} Returns the new recipe created.
   * @memberof RecipesService
   */
  async function duplicate (workspaceId, cloneRecipeId) {
    return await repository.create(`workspaces/${workspaceId}/recipes`, { cloneRecipeId })
  }

  /**
   * Updates a recipe.
   *
   * @param {String} recipeId
   * @param {Object} recipe
   * @returns {Promise<Object>} Returns the updated recipe.
   * @memberof RecipesService
   */
  async function update (recipeId, recipe) {
    return await repository.update('recipes', recipeId, recipe)
  }

  /**
   * Sets the deletedAt attribute of the given recipe to the current date and time.
   *
   * @param {String} workspaceId
   * @param {String} recipeId
   * @returns {Promise<string>} Returns the id of the deleted recipe.
   * @memberof RecipesService
   */
  async function del (recipeId) {
    return await repository.delete('recipes', recipeId)
  }

  return {
    create,
    delete: del,
    duplicate,
    find,
    findOne,
    update
  }
}
