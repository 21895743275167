import themeUtils from '~/lib/utils/theme.utils'

const actions = {
  previewThemeProperty ({ commit }, { key, value }) {
    themeUtils.properties[key].setValue(value)
    commit('SET_THEME_PROPERTY_VALUE', { key, value })
  },

  revertThemePreviewChanges ({ commit, dispatch }, originalTheme) {
    const colors = themeUtils.getColors(originalTheme)

    commit('SET_THEME_PROPERTY_VALUE', { key: 'name', value: originalTheme.name })
    dispatch('previewThemeProperty', { key: 'fontName', value: originalTheme.fontName })
    dispatch('previewThemeProperty', { key: 'fontSizeScale', value: originalTheme.fontSizeScale })
    dispatch('previewThemeProperty', { key: 'buttonBorderRadius', value: originalTheme.buttonBorderRadius })

    for (let i = 0; i < colors.length; i++) {
      const { key, value } = colors[i]
      dispatch('previewThemeProperty', { key, value })
    }
  }
}

const mutations = {
  SET_THEME_PROPERTY_VALUE (state, { key, value }) {
    state.form.theme[key] = value
  }
}

const getters = {
  getCurrentThemeId (state) {
    return state.form?.theme?.id || null
  },

  getFormTheme (state) {
    return state.form?.theme
  },

  getThemeFieldTextColor (state) {
    return state.form?.theme?.fieldTextColor
  },

  getThemeAnswerTextColor (state) {
    return state.form?.theme?.answerTextColor
  },

  getThemeAnswerBackgroundColor (state) {
    return state.form?.theme?.answerBackgroundColor
  },

  getThemeAnswerBackgroundColorHover (state) {
    return state.form?.theme?.answerBackgroundColorHover
  },

  getThemeButtonBackgroundColor (state) {
    return state.form?.theme?.buttonBackgroundColor
  },

  getThemeButtonTextColor (state) {
    return state.form?.theme?.buttonTextColor
  },

  getThemePageBackgroundColor (state) {
    return state.form?.theme?.pageBackgroundColor
  },

  getThemeFontSizeScale (state) {
    return state.form?.theme?.fontSizeScale
  },

  getThemeButtonBorderRadius (state) {
    return state.form?.theme?.buttonBorderRadius
  },

  getThemeFontName (state) {
    return state.form?.theme?.fontName
  }
}

export default {
  actions,
  mutations,
  getters
}
