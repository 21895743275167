
import { mapState } from 'vuex'
import notificationsMixin from '~/lib/mixins/notifications.mixin'

export default {
  name: 'NotificationsDropdown',
  mixins: [notificationsMixin],

  props: {
    title: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      default: 'oi-alarm-bell'
    },
    categories: {
      type: Array,
      default: () => []
    },
    excludeCategories: {
      type: Array,
      default: () => []
    },
    showPreview: {
      type: Boolean,
      default: false
    },
    previewPriority: {
      type: Number,
      default: null
    },
    alwaysShow: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      notifications: [],
      loading: false,
      loadingMore: false,
      isLoading: false,
      error: null,
      page: 1,
      hasMore: true,
      isClosing: false,
      isActive: false,
      lastUnreadNotification: null,
      dropdownId: `notifications-${Math.random().toString(36).substr(2, 9)}`
    }
  },

  computed: {
    ...mapState('notifications', ['activePreviewId', 'unreadCounts']),

    unreadCount () {
      const category = this.categories?.length ? this.categories[0] : 'general'
      const count = this.unreadCounts[category] || 0
      return count
    },

    shouldShowDropdown () {
      return this.alwaysShow || this.unreadCount > 0
    },

    canShowPreview () {
      const can = this.activePreviewId === this.dropdownId
      return can
    }
  },

  async created () {
    await this.updateUnreadCount()
    await this.fetchLastUnreadNotification()

    if (this.showPreview && this.previewPriority) {
      this.$store.commit('notifications/registerPreviewPriority', {
        id: this.dropdownId,
        priority: this.previewPriority,
        unreadCount: this.unreadCount
      })

      this.$emit('mounted')
    }
  },

  beforeDestroy () {
    this.$store.commit('notifications/unregisterPreview', this.dropdownId)
  },

  methods: {
    generateId () {
      return Math.random().toString(36).substring(2, 10)
    },

    getApiParams () {
      const params = {}
      if (this.categories.length > 0) {
        params.categories = this.categories
      }
      if (this.excludeCategories.length > 0) {
        params.excludeCategories = this.excludeCategories
      }
      return params
    },

    async fetchNotifications (reset = false) {
      if (this.isLoading) {
        return
      }

      try {
        this.isLoading = true

        if (reset) {
          this.notifications = []
          this.page = 1
          this.hasMore = true
        }

        if (!this.hasMore) {
          return
        }

        const params = {
          page: this.page,
          limit: 10,
          ...this.getApiParams()
        }

        const [notificationsResponse, lastUnreadResponse] = await Promise.all([
          this.$notificationsService.find(params),
          reset ? this.$notificationsService.find({ page: 1, limit: 1, read: false, ...this.getApiParams() }) : null
        ].filter(Boolean))

        const newNotifications = notificationsResponse.data.filter(
          newNotification => !this.notifications.some(
            existingNotification => existingNotification.id === newNotification.id
          )
        )

        if (reset) {
          this.notifications = notificationsResponse.data
          if (lastUnreadResponse?.data?.length > 0) {
            this.lastUnreadNotification = lastUnreadResponse.data[0]
          }
        } else {
          this.notifications = [...this.notifications, ...newNotifications]
        }

        this.hasMore = notificationsResponse.data.length === params.limit
        if (this.hasMore) {
          this.page++
        }
      } catch (error) {
        console.error('Error fetching notifications:', error)
        this.error = error
      } finally {
        this.loading = false
        this.loadingMore = false
        this.isLoading = false
      }
    },

    async fetchLastUnreadNotification () {
      try {
        const params = {
          page: 1,
          limit: 1,
          read: false,
          ...this.getApiParams()
        }

        const response = await this.$notificationsService.find(params)
        if (response.data.length > 0) {
          this.lastUnreadNotification = response.data[0]
        } else {
          this.lastUnreadNotification = null
        }
      } catch (error) {
        console.error('Error fetching last unread notification:', error)
      }
    },

    handleScroll ({ target }) {
      const { scrollTop, scrollHeight, clientHeight } = target

      if (scrollTop + clientHeight >= scrollHeight - 50 && !this.isLoading && this.hasMore) {
        this.loadingMore = true
        this.fetchNotifications()
          .finally(() => {
            this.loadingMore = false
          })
      }
    },

    async toggleRead (notification) {
      try {
        const wasRead = !!notification.readAt
        let response

        // Use the appropriate method based on current read status
        if (wasRead) {
          response = await this.$notificationsService.markAsUnread(notification.id)
          // Increment unread count
          if (this.categories?.length) {
            this.$store.commit('notifications/incrementUnreadCount', this.categories[0])
          } else {
            this.$store.commit('notifications/incrementUnreadCount', 'general')
          }
          this.$store.commit('notifications/incrementUnreadCount', 'all')
        } else {
          response = await this.$notificationsService.markAsRead(notification.id)
          // Decrement unread count
          if (this.categories?.length) {
            this.$store.commit('notifications/decrementUnreadCount', this.categories[0])
          } else {
            this.$store.commit('notifications/decrementUnreadCount', 'general')
          }
          this.$store.commit('notifications/decrementUnreadCount', 'all')
        }

        // Update the notification in the list with the full response data
        const index = this.notifications.findIndex(n => n.id === notification.id)
        if (index !== -1) {
          // Use Vue.set to ensure reactivity
          this.$set(this.notifications, index, response.data)
        }

        // Always fetch last unread notification to keep preview up to date
        await this.fetchLastUnreadNotification()

        // Force a re-render of the notification list
        this.notifications = [...this.notifications]
      } catch (error) {
        console.error('Error toggling notification read status:', error)
      }
    },

    async markAllAsRead () {
      try {
        const params = {
          read: true
        }

        if (this.categories?.length) {
          params.categories = this.categories
        }

        await this.$notificationsService.markAllAsRead(params)

        if (this.categories?.length) {
          this.$store.commit('notifications/resetUnreadCount', this.categories[0])
        } else {
          this.$store.commit('notifications/resetUnreadCount', 'general')
        }
        this.$store.commit('notifications/resetUnreadCount', 'all')

        await this.updateUnreadCount()
      } catch (error) {
        console.error('Error marking all notifications as read:', error)
      }
    },

    handleNotificationClick (notification, _event) {
      if (notification.data.link) {
        window.open(notification.data.link, '_blank')
      }
    },

    getNotificationIcon (notification) {
      return notification.data.icon || 'oi-alarm-bell'
    },

    getNotificationIconClass (notification) {
      return notification.data.iconClass || 'text-neutral'
    },

    formatDate (date) {
      return this.$dayjs(date).fromNow()
    },

    async toggleDropdown (active) {
      if (active) {
        this.loading = true
        await this.fetchNotifications(true)
      } else {
        this.isClosing = true
        await this.$nextTick()
        this.notifications = []
        this.page = 1
        this.hasMore = true
        this.isClosing = false
      }
    },

    handlePreviewEnter () {
      if (!this.showPreview || !this.lastUnreadNotification) {
        return
      }

      if (!this.unreadCount) {
        return
      }

      this.$store.dispatch('notifications/showPreview', this.dropdownId)
    },

    handlePreviewLeave () {
      // Don't hide preview if it's the initial preview
      if (!this.$store.state.notifications.initialPreviewShown) {
        return
      }

      this.$store.dispatch('notifications/hidePreview', this.dropdownId)
    },

    async handleDropdownEnter () {
      if (!this.loading) {
        this.loading = true
        try {
          await this.fetchNotifications(true)
        } catch (error) {
          console.error('Error fetching notifications:', error)
          this.error = error.message
        } finally {
          this.loading = false
        }
      }
    }
  }
}
