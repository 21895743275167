import useRepository from './repository/apiclient-axios.repository'

export default function useNotificationsService ({ $axios }) {
  const repository = useRepository({ $axios })

  /**
   * Returns all notifications for the authenticated user.
   *
   * @param {Object} params - Query parameters
   * @param {Number} params.page - Page number
   * @param {Number} params.limit - Number of items per page
   * @param {Boolean} params.read - Filter by read status
   * @returns {Promise<Object>} Returns an object containing notifications and metadata
   */
  async function find (params = {}) {
    const response = await repository.find('notifications', params)
    return response
  }

  /**
   * Returns the count of unread notifications for the authenticated user.
   *
   * @param {Object} params - Query parameters
   * @param {String[]} params.categories - Filter by notification categories
   * @returns {Promise<Number>} Returns the count of unread notifications
   */
  async function getUnreadCount (params = {}) {
    try {
      const queryParams = { read: false, ...params }
      const response = await repository.find('notifications', queryParams)
      return response.meta?.unread_count || 0
    } catch (error) {
      console.error('NotificationsService - Error getting unread count:', error)
      return 0
    }
  }

  /**
   * Marks all notifications as read for the authenticated user.
   *
   * @param {Object} params - Query parameters
   * @param {String[]} params.categories - Filter by notification categories
   * @returns {Promise<Object>} Returns the response from the API
   */
  async function markAllAsRead (params = {}) {
    const response = await $axios.put('notifications', { read: true, ...params })
    return response
  }

  /**
   * Marks a specific notification as read.
   *
   * @param {String} notificationId - ID of the notification to mark as read
   * @returns {Promise<Object>} Returns the response from the API
   */
  async function markAsRead (notificationId) {
    const response = await $axios.put(`notifications/${notificationId}`, { read: true })
    return response
  }

  /**
   * Marks a specific notification as unread.
   *
   * @param {String} notificationId - ID of the notification to mark as unread
   * @returns {Promise<Object>} Returns the response from the API
   */
  async function markAsUnread (notificationId) {
    const response = await $axios.put(`notifications/${notificationId}`, { read: false })
    return response
  }

  return {
    find,
    getUnreadCount,
    markAllAsRead,
    markAsRead,
    markAsUnread
  }
}
